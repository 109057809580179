<template>
<div>
    <v-card-title class="display-1 mb-2">
        {{t('$vuetify.serviceCenter.requestCharger')}}
        <v-spacer/>
        <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-icon
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
                large
                >
                    mdi-progress-question
                </v-icon>
            </template>
            <span>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.<br>
                Magnam, neque totam quasi ipsum necessitatibus amet <br>
                excepturi ex corporis, aut impedit in ratione animi<br>
                fugit dolorem voluptatum vel recusandae eaque molestias!
            </span>
        </v-tooltip>
    </v-card-title>
            <v-card-subtitle>{{t('$vuetify.serviceCenter.questionChargerType')}}</v-card-subtitle>
            <v-card-text>
            <v-radio-group v-model="radioGroupGuestCharging">
            <v-row>
                <v-col cols="12"  lg="4">
                    <v-card flat outlined min-height="250px">
                        <v-card-text>
                            <v-radio :label="t('$vuetify.serviceCenter.standard')"></v-radio>
                        </v-card-text>
                        <v-card-text align="center">
                            <v-img
                                width="30%"
                                lazy-src="/requests/standard.svg"
                                src="/requests/standard.svg"
                                class="mb-2"
                            ></v-img> <br>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12"  lg="4">
                    <v-card flat outlined min-height="250px">
                        <v-card-text>
                            <v-radio
                                :label="t('$vuetify.serviceCenter.mountingTesting')"
                            ></v-radio>
                        </v-card-text>
                        <v-card-text align="center">
                                <v-img
                                width="30%"
                                lazy-src="/requests/testing.svg"
                                src="/requests/testing.svg"
                                class="mb-2"
                            ></v-img>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12"  lg="4">
                  <v-card flat outlined min-height="250px">
                    <v-card-text>
                      <v-radio :label="t('$vuetify.serviceCenter.custom')"></v-radio>
                    </v-card-text>
                    <v-card-text align="center">
                      <v-img
                        width="25%"
                        lazy-src="/requests/customize.svg"
                        src="/requests/customize.svg"
                        class="mb-2"
                      ></v-img>
                    </v-card-text>
                    <v-card-text>
                      {{t('$vuetify.serviceCenter.remark')}}
                    </v-card-text>
                  </v-card>
                </v-col>
            </v-row>
            </v-radio-group>
        </v-card-text>
      <v-divider></v-divider>
            <v-card-actions class="justify-end">
      <!-- {{t('$vuetify.serviceCenter.totPrice')}} : {{totPrice}} € -->
      <v-spacer />
      <v-btn
          color="primary"
          text
          @click="previousStep()"
      >
          <v-icon>mdi-chevron-left</v-icon>
          {{t('$vuetify.generic.back')}}
      </v-btn>
      <v-btn
          color="success"
          text
          @click="nextStep()"
          small
      >
        {{t('$vuetify.generic.next')}}
      </v-btn>
      <v-btn
        color="red"
        text
        @click="cancel"
      >
        {{t('$vuetify.generic.cancel')}}
      </v-btn>
    </v-card-actions>
</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      radioGroupGuestCharging: 1
    }
  },
  methods: {
    nextStep () {
      if (this.radioGroupGuestCharging === 0) {
        this.$store.commit('requestsState/setInstallationType', '13842')
        this.$store.commit('requestsState/setStep', 'SubscriptionType')
      } else if (this.radioGroupGuestCharging === 1) {
        this.$store.commit('requestsState/setInstallationType', '13843')
        this.$store.commit('requestsState/setStep', 'SubscriptionType')
      } else {
        this.$store.commit('requestsState/setInstallationType', '13844')
        this.$store.commit('requestsState/setStep', 'SubscriptionType')
      }
    },
    previousStep () {
      this.$store.commit('requestsState/setInstallationType', '')
      this.$store.commit('requestsState/setPreviousStepName')
    },
    cancel () {
      this.$emit('cancel')
    }
  },
  computed: {
    ...mapGetters({ price: 'requestsState/price', totPrice: 'requestsState/totalPrice' })
  }
}
</script>
